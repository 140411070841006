@keyframes timeline-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(99, 102, 241, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(99, 102, 241, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(99, 102, 241, 0);
  }
}

/* Typing animation for year text */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* Apply animations */
.group:hover .w-4.h-4.bg-indigo-500.rounded-full {
  animation: timeline-pulse 2s infinite;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .timeline-container {
    padding-left: 0;
  }
}

/* Optional: Add a retro "scanline" effect to the career window */
.career-window::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    rgba(18, 16, 16, 0) 50%,
    rgba(0, 0, 0, 0.025) 50%
  );
  background-size: 100% 4px;
  pointer-events: none;
  z-index: 1;
}
