@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import VT323 font for that perfect retro look */
@import url("https://fonts.googleapis.com/css2?family=VT323&display=swap");

/* Base cursor */
* {
  cursor:
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='%236366f1' d='M0,0 L14,7 L7,7 L7,14 L0,0' /%3E%3C/svg%3E")
      0 0,
    auto;
}

/* Clickable elements cursor */
a,
button,
input[type="submit"],
input[type="button"],
input[type="checkbox"],
input[type="radio"],
select,
.clickable {
  cursor:
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='%236366f1' d='M0,0 L14,7 L7,7 L7,14 L0,0' /%3E%3C/svg%3E")
      0 0,
    auto;
}

/* Text selection cursor */
text,
textarea {
  cursor:
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath d='M4,0 L4,16 M0,8 L8,8' stroke='%236366f1' stroke-width='2' /%3E%3C/svg%3E")
      0 0,
    text;
}

/* Retro scrollbar styling */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #e0e7ff; /* Indigo 100 */
  border: 2px solid #6366f1; /* Indigo 500 */
}

::-webkit-scrollbar-thumb {
  background: #818cf8; /* Indigo 400 */
  border: 2px solid #6366f1; /* Indigo 500 */
}

::-webkit-scrollbar-thumb:hover {
  background: #6366f1; /* Indigo 500 */
}

/* Retro button styles */
button, input[type="submit"], input[type="button"] {
  background-color: #c0c0c0;
  border: 2px outset #ffffff;
  color: #000000;
  padding: 2px 6px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-family: 'VT323', monospace;
  font-size: 16px;
  margin: 4px 2px;
}

button:active, input[type="submit"]:active, input[type="button"]:active {
  border-style: inset;
}

/* Add a subtle CRT effect to the entire page */
/*@keyframes flicker {
  0% { opacity: 0.93; }
  5% { opacity: 0.97; }
  10% { opacity: 0.9; }
  15% { opacity: 0.95; }
  20% { opacity: 0.98; }
  25% { opacity: 0.92; }
  30% { opacity: 0.96; }
  35% { opacity: 0.95; }
  40% { opacity: 0.98; }
  45% { opacity: 0.91; }
  50% { opacity: 0.97; }
  55% { opacity: 0.96; }
  60% { opacity: 0.94; }
  65% { opacity: 0.98; }
  70% { opacity: 0.92; }
  75% { opacity: 0.95; }
  80% { opacity: 0.93; }
  85% { opacity: 0.97; }
  90% { opacity: 0.94; }
  95% { opacity: 0.96; }
  100% { opacity: 0.95; }
}*/

body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%);
  background-size: 100% 4px;
  z-index: 1000000;
  pointer-events: none;
  animation: flicker 0.15s infinite;
  opacity: 0.1;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-slideIn {
  animation: slideIn 0.5s ease-out forwards;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .window-container {
    margin-bottom: 1rem;
  }

  pre code {
    font-size: 14px;
  }
}

@keyframes scanlines {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 100%;
  }
}

.bg-scanlines {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 50%,
    rgba(0, 0, 0, 0.2) 50%
  );
  background-size: 100% 4px;
  animation: scanlines 10s linear infinite;
}

@keyframes frog-bounce {
  0%, 100% {
    transform: translateY(0) scale(1);
  }
  50% {
    transform: translateY(-20px) scale(1.05);
  }
}

.animate-frog-bounce {
  animation: frog-bounce 1s infinite ease-in-out;
  transform-origin: center bottom;
}