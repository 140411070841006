@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes growBar {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.skill-bar-animation {
  animation: growBar 0.5s ease-out forwards;
}

.category-transition {
  animation: fadeIn 0.5s ease-out;
}

.skill-hover-info {
  background-color: rgba(99, 102, 241, 0.9);
  color: white;
  transition: opacity 0.3s ease-in-out;
}